import { j as f, T as Ve, c as M, s as Ze, g as et, p as R, k as se, a as tt, S as rt, h as nt, b as ot, i as at } from "./ToggleSwitch-DvVsR0h0.mjs";
import H, { useRef as I, useCallback as w, forwardRef as X, useMemo as $, useState as K, useEffect as E, useId as Ce, createElement as ct, createContext as lt, useContext as it, useLayoutEffect as Be, memo as st } from "react";
import { createPortal as ut } from "react-dom";
const pt = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), Ne = {}, dt = (e, t, r, n) => {
  const o = `${e}-${t ? "currency" : "noCurrency"}-${r}-${n}`;
  let l = Ne[o];
  return l || (l = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: r,
    maximumFractionDigits: r,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...n ? {} : { useGrouping: !1 }
  }), Ne[o] = l), l;
};
function xe(e, t, r = {}) {
  const n = (r == null ? void 0 : r.precision) === void 0 ? 2 : r == null ? void 0 : r.precision, a = typeof t == "number" ? t || 0 : pt(e, t), o = (r == null ? void 0 : r.thousandSeparator) ?? !0;
  return dt(e, !(r != null && r.format), n, o).format(a).replace(/\u00A0/, " ");
}
function Fe(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), r = parseFloat(t) || 0;
  return Math.round(r * 100);
}
function ue(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
function ft(e) {
  const t = I(null), r = w(
    (n) => {
      if (t.current = n, !!e) {
        if (typeof e == "function") return e(n);
        e.current = n;
      }
    },
    [e]
  );
  return [t, r];
}
function Le(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const bt = X(
  ({
    label: e,
    locale: t = "de",
    name: r,
    id: n,
    min: a = 0,
    onChange: o,
    onBlur: l,
    onFocus: c,
    defaultValue: i,
    value: s,
    step: d,
    children: k,
    className: m,
    hideSteps: g,
    max: v,
    ...b
  }, y) => {
    const p = $(() => mt(d), [d]), [x, S] = K(
      ue(
        xe(t, s ?? i ?? "", { precision: p, format: "%v", thousandSeparator: !1 }),
        p
      )
    ), N = $(() => Le(v), [v]), L = $(() => Le(a), [a]), [O, j] = K(null), [u, h] = ft(y), z = w(
      (C) => {
        const F = Oe(C.target.value, t, p), Z = Fe(F);
        if (typeof N < "u" && Z > N || typeof L < "u" && Z < L) {
          const ae = C.target.selectionStart ? C.target.selectionStart : 0;
          return j(ae), C.preventDefault();
        }
        j(C.target.selectionStart);
        let ne = C.target.value;
        F.startsWith(C.target.value) || (ne = F);
        const oe = Fe(C.target.value);
        C.target.value && !isNaN(oe) && Z === oe && (o == null || o(C)), S(ne);
      },
      [t, p, L, N, o]
    ), T = w(
      (C) => {
        const F = Oe(C.target.value, t, p);
        F !== C.target.value && (C.target.value = F), j(null), l == null || l(C);
      },
      [l, p, t]
    ), B = w(
      (C) => {
        j(C.target.selectionStart), c == null || c(C);
      },
      [c]
    ), U = $(
      () => i ? { defaultValue: x } : { value: x },
      [i, x]
    );
    return E(() => {
      if (typeof i < "u") return;
      const C = s ?? i;
      if (typeof s > "u")
        return S("");
      const F = ue(
        xe(t, C, { precision: p, format: "%v", thousandSeparator: !1 }),
        p
      );
      S(F);
    }, [s, p, i, t]), E(() => {
      const C = (x == null ? void 0 : x.length) ?? 0, F = (O ?? 0) > C ? C : O;
      O === null || !u.current || document.activeElement !== u.current || u.current.setSelectionRange(F, F);
    }, [O, u, x]), /* @__PURE__ */ f.jsx(
      Ve,
      {
        ...b,
        ...U,
        onFocus: B,
        onBlur: T,
        onChange: z,
        step: d,
        ref: h,
        id: n || r,
        name: r,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: M(m, { [Ze.hideSteps]: g }),
        children: k
      }
    );
  }
);
bt.displayName = "ClientAmountField";
function Oe(e, t, r = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const n = String(e), a = ue(n, r), o = xe(t, a, { precision: r, format: "%v", thousandSeparator: !1 }), l = ue(o, r);
  if (!r)
    return l;
  const c = l.search(/[.,]/);
  if (c < 0)
    return l;
  const i = r - (l.length - 1 - c);
  return l.concat("0".repeat(i));
}
function mt(e) {
  var r;
  return e === void 0 ? void 0 : ((r = String(e).split(".")[1]) == null ? void 0 : r.length) ?? 0;
}
const Cr = X(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, l = o ? R : t, c = o ? R : r;
  return /* @__PURE__ */ f.jsx(et, { ...n, onChange: l, onClick: c, ref: a });
}), ht = "_picker_4fkbz_1", gt = "_swatch_4fkbz_7", wt = "_activeSwatch_4fkbz_19", yt = "_inactiveSwatch_4fkbz_25", xt = "_swatchInner_4fkbz_30", vt = "_activePickerSwatch_4fkbz_37", Tt = "_popover_4fkbz_46", kt = "_pickerSwatches_4fkbz_68", Ct = "_input_4fkbz_81", St = "_label_4fkbz_104", q = {
  picker: ht,
  swatch: gt,
  activeSwatch: wt,
  inactiveSwatch: yt,
  swatchInner: xt,
  activePickerSwatch: vt,
  popover: Tt,
  pickerSwatches: kt,
  input: Ct,
  label: St
};
function be() {
  return (be = Object.assign || function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = arguments[t];
      for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
    }
    return e;
  }).apply(this, arguments);
}
function Se(e, t) {
  if (e == null) return {};
  var r, n, a = {}, o = Object.keys(e);
  for (n = 0; n < o.length; n++) t.indexOf(r = o[n]) >= 0 || (a[r] = e[r]);
  return a;
}
function pe(e) {
  var t = I(e), r = I(function(n) {
    t.current && t.current(n);
  });
  return t.current = e, r.current;
}
var te = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = 1), e > r ? r : e < t ? t : e;
}, ee = function(e) {
  return "touches" in e;
}, ve = function(e) {
  return e && e.ownerDocument.defaultView || self;
}, je = function(e, t, r) {
  var n = e.getBoundingClientRect(), a = ee(t) ? function(o, l) {
    for (var c = 0; c < o.length; c++) if (o[c].identifier === l) return o[c];
    return o[0];
  }(t.touches, r) : t;
  return { left: te((a.pageX - (n.left + ve(e).pageXOffset)) / n.width), top: te((a.pageY - (n.top + ve(e).pageYOffset)) / n.height) };
}, ze = function(e) {
  !ee(e) && e.preventDefault();
}, $e = H.memo(function(e) {
  var t = e.onMove, r = e.onKey, n = Se(e, ["onMove", "onKey"]), a = I(null), o = pe(t), l = pe(r), c = I(null), i = I(!1), s = $(function() {
    var g = function(y) {
      ze(y), (ee(y) ? y.touches.length > 0 : y.buttons > 0) && a.current ? o(je(a.current, y, c.current)) : b(!1);
    }, v = function() {
      return b(!1);
    };
    function b(y) {
      var p = i.current, x = ve(a.current), S = y ? x.addEventListener : x.removeEventListener;
      S(p ? "touchmove" : "mousemove", g), S(p ? "touchend" : "mouseup", v);
    }
    return [function(y) {
      var p = y.nativeEvent, x = a.current;
      if (x && (ze(p), !function(N, L) {
        return L && !ee(N);
      }(p, i.current) && x)) {
        if (ee(p)) {
          i.current = !0;
          var S = p.changedTouches || [];
          S.length && (c.current = S[0].identifier);
        }
        x.focus(), o(je(x, p, c.current)), b(!0);
      }
    }, function(y) {
      var p = y.which || y.keyCode;
      p < 37 || p > 40 || (y.preventDefault(), l({ left: p === 39 ? 0.05 : p === 37 ? -0.05 : 0, top: p === 40 ? 0.05 : p === 38 ? -0.05 : 0 }));
    }, b];
  }, [l, o]), d = s[0], k = s[1], m = s[2];
  return E(function() {
    return m;
  }, [m]), H.createElement("div", be({}, n, { onTouchStart: d, onMouseDown: d, className: "react-colorful__interactive", ref: a, onKeyDown: k, tabIndex: 0, role: "slider" }));
}), He = function(e) {
  return e.filter(Boolean).join(" ");
}, We = function(e) {
  var t = e.color, r = e.left, n = e.top, a = n === void 0 ? 0.5 : n, o = He(["react-colorful__pointer", e.className]);
  return H.createElement("div", { className: o, style: { top: 100 * a + "%", left: 100 * r + "%" } }, H.createElement("div", { className: "react-colorful__pointer-fill", style: { backgroundColor: t } }));
}, Q = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = Math.pow(10, t)), Math.round(r * e) / r;
}, _t = function(e) {
  var t = e.s, r = e.v, n = e.a, a = (200 - t) * r / 100;
  return { h: Q(e.h), s: Q(a > 0 && a < 200 ? t * r / 100 / (a <= 100 ? a : 200 - a) * 100 : 0), l: Q(a / 2), a: Q(n, 2) };
}, Te = function(e) {
  var t = _t(e);
  return "hsl(" + t.h + ", " + t.s + "%, " + t.l + "%)";
};
H.memo(function(e) {
  var t = e.hue, r = e.onChange, n = He(["react-colorful__hue", e.className]);
  return H.createElement("div", { className: n }, H.createElement($e, { onMove: function(a) {
    r({ h: 360 * a.left });
  }, onKey: function(a) {
    r({ h: te(t + 360 * a.left, 0, 360) });
  }, "aria-label": "Hue", "aria-valuenow": Q(t), "aria-valuemax": "360", "aria-valuemin": "0" }, H.createElement(We, { className: "react-colorful__hue-pointer", left: t / 360, color: Te({ h: t, s: 100, v: 100, a: 1 }) })));
});
H.memo(function(e) {
  var t = e.hsva, r = e.onChange, n = { backgroundColor: Te({ h: t.h, s: 100, v: 100, a: 1 }) };
  return H.createElement("div", { className: "react-colorful__saturation", style: n }, H.createElement($e, { onMove: function(a) {
    r({ s: 100 * a.left, v: 100 - 100 * a.top });
  }, onKey: function(a) {
    r({ s: te(t.s + 100 * a.left, 0, 100), v: te(t.v - 100 * a.top, 0, 100) });
  }, "aria-label": "Color", "aria-valuetext": "Saturation " + Q(t.s) + "%, Brightness " + Q(t.v) + "%" }, H.createElement(We, { className: "react-colorful__saturation-pointer", top: 1 - t.v / 100, left: t.s / 100, color: Te(t) })));
});
var Et = /^#?([0-9A-F]{3,8})$/i, At = function(e) {
  var t = e.color, r = t === void 0 ? "" : t, n = e.onChange, a = e.onBlur, o = e.escape, l = e.validate, c = e.format, i = e.process, s = Se(e, ["color", "onChange", "onBlur", "escape", "validate", "format", "process"]), d = K(function() {
    return o(r);
  }), k = d[0], m = d[1], g = pe(n), v = pe(a), b = w(function(p) {
    var x = o(p.target.value);
    m(x), l(x) && g(i ? i(x) : x);
  }, [o, i, l, g]), y = w(function(p) {
    l(p.target.value) || m(o(r)), v(p);
  }, [r, o, l, v]);
  return E(function() {
    m(o(r));
  }, [r, o]), H.createElement("input", be({}, s, { value: c ? c(k) : k, spellCheck: "false", onChange: b, onBlur: y }));
}, Ie = function(e) {
  return "#" + e;
}, Pt = function(e) {
  var t = e.prefixed, r = e.alpha, n = Se(e, ["prefixed", "alpha"]), a = w(function(l) {
    return l.replace(/([^0-9A-F]+)/gi, "").substring(0, r ? 8 : 6);
  }, [r]), o = w(function(l) {
    return function(c, i) {
      var s = Et.exec(c), d = s ? s[1].length : 0;
      return d === 3 || d === 6 || !!i && d === 4 || !!i && d === 8;
    }(l, r);
  }, [r]);
  return H.createElement(At, be({}, n, { escape: a, format: t ? Ie : void 0, process: Ie, validate: o }));
};
const Nt = {
  "betterplace-dimension-0": "1rem",
  "betterplace-dimension-1": "1.25rem",
  "betterplace-dimension-2": "1.5rem",
  "betterplace-dimension-3": "2rem",
  "betterplace-dimension-4": "3rem",
  "betterplace-dimension-5": "4rem",
  "betterplace-dimension-6": "5rem",
  "betterplace-dimension-7": "6.25rem",
  "betterplace-dimension-8": "12.5rem",
  "betterplace-dimension-9": "20rem",
  "betterplace-dimension-10": "30rem",
  "betterplace-dimension--1": "0.75rem",
  "betterplace-dimension--2": "0.5rem",
  "betterplace-dimension--3": "0.25rem",
  "betterplace-dimension--4": "0.125rem",
  "betterplace-dimension--5": "0.0625rem",
  "betterplace-dimension-none": "0rem",
  "betterplace-dimension-negative-1": "-0.75rem",
  "betterplace-dimension-negative-2": "-0.5rem",
  "betterplace-dimension-negative-3": "-0.25rem",
  "betterplace-dimension-negative-4": "-0.125rem",
  "betterplace-dimension-negative-5": "-0.0625rem",
  "betterplace-opacity-low": "10%",
  "betterplace-opacity-md": "50%",
  "betterplace-opacity-high": "90%",
  "betterplace-letter-spacing-0": "0%",
  "betterplace-letter-spacing-increased": "150%",
  "betterplace-letter-spacing-decreased": "-5%",
  "betterplace-paragraph-spacing-0": "0",
  "betterplace-paragraph-spacing-md": "0.75rem",
  "betterplace-paragraph-spacing-sm": "0.5rem",
  "betterplace-color-gray-100": "#f8f8f8",
  "betterplace-color-gray-200": "#eeeeee",
  "betterplace-color-gray-300": "#cccccc",
  "betterplace-color-gray-400": "#acacac",
  "betterplace-color-gray-500": "#919191",
  "betterplace-color-gray-600": "#7b7b7b",
  "betterplace-color-gray-700": "#636363",
  "betterplace-color-gray-800": "#3f3f3f",
  "betterplace-color-gray-900": "#282828",
  "betterplace-color-green-100": "#f5fccc",
  "betterplace-color-green-200": "#eaf99a",
  "betterplace-color-green-300": "#d6ef66",
  "betterplace-color-green-400": "#bedf40",
  "betterplace-color-green-500": "#9ecb0a",
  "betterplace-color-green-600": "#85ac1c",
  "betterplace-color-green-700": "#549205",
  "betterplace-color-green-800": "#357c00",
  "betterplace-color-green-900": "#2a5807",
  "betterplace-color-purple-100": "#f7dae8",
  "betterplace-color-purple-200": "#f0b7d8",
  "betterplace-color-purple-300": "#d387ba",
  "betterplace-color-purple-400": "#a75c96",
  "betterplace-color-purple-500": "#833c79",
  "betterplace-color-purple-600": "#6d2c64",
  "betterplace-color-purple-700": "#612058",
  "betterplace-color-purple-800": "#4d1a43",
  "betterplace-color-purple-900": "#381835",
  "betterplace-color-teal-100": "#e4fbee",
  "betterplace-color-teal-200": "#c9f7e2",
  "betterplace-color-teal-300": "#a7e7d0",
  "betterplace-color-teal-400": "#88d0bd",
  "betterplace-color-teal-500": "#60b2a4",
  "betterplace-color-teal-600": "#309b89",
  "betterplace-color-teal-700": "#2b8475",
  "betterplace-color-teal-800": "#1e6761",
  "betterplace-color-teal-900": "#114e47",
  "betterplace-color-yellow-100": "#fff4d2",
  "betterplace-color-yellow-200": "#fbe8af",
  "betterplace-color-yellow-300": "#f9da7c",
  "betterplace-color-yellow-400": "#f6ce46",
  "betterplace-color-yellow-500": "#f3b700",
  "betterplace-color-yellow-600": "#f3a900",
  "betterplace-color-yellow-700": "#f39600",
  "betterplace-color-yellow-800": "#d86c00",
  "betterplace-color-yellow-900": "#c44400",
  "betterplace-color-red-100": "#fee2e2",
  "betterplace-color-red-200": "#ffc4c4",
  "betterplace-color-red-300": "#fca5a5",
  "betterplace-color-red-400": "#f87171",
  "betterplace-color-red-500": "#ef4444",
  "betterplace-color-red-600": "#dc2626",
  "betterplace-color-red-700": "#d32b43",
  "betterplace-color-red-800": "#b11b3e",
  "betterplace-color-red-900": "#931c38",
  "betterplace-color-light": "#ffffff",
  "betterplace-color-darkness": "#000000",
  "betterplace-color-blue-dark": "#245d8c",
  "betterplace-color-opacity-white-40": "rgba(255, 255, 255, 0.4)",
  "betterplace-color-opacity-white-70": "rgba(255, 255, 255, 0.7)",
  "betterplace-color-opacity-black-10": "rgba(0, 0, 0, 0.1)",
  "betterplace-color-opacity-black-20": "rgba(0, 0, 0, 0.2)",
  "betterplace-color-opacity-black-30": "rgba(0, 0, 0, 0.3)",
  "betterplace-color-opacity-black-40": "rgba(0, 0, 0, 0.4)",
  "betterplace-color-opacity-black-50": "rgba(0, 0, 0, 0.5)",
  "betterplace-color-opacity-black-60": "rgba(0, 0, 0, 0.6)",
  "betterplace-color-opacity-black-70": "rgba(0, 0, 0, 0.7)",
  "betterplace-color-opacity-black-80": "rgba(0, 0, 0, 0.8)",
  "betterplace-color-opacity-black-90": "rgba(0, 0, 0, 0.9)",
  "betterplace-me-color-orange-500": "#ff854d",
  "betterplace-me-color-orange-600": "#DB6038",
  "betterplace-me-color-orange-800": "#6F1F06",
  "betterplace-me-color-blue-100": "#E0F4F9",
  "betterplace-me-color-blue-500": "#00A0C8",
  "betterplace-me-color-blue-700": "#005D90",
  "betterplace-me-color-green-special": "#EBF2E6",
  "betterplace-external-color-blue-facebook": "#4E69A2",
  "betterplace-external-color-blue-twitter": "#1DA1F2",
  "betterplace-external-color-pink-instagram": "#E65083",
  "betterplace-external-color-blue-linkedin": "#0A66C2",
  "betterplace-external-color-red-sparkasse": "#ff0000",
  "betterplace-font-families-fira-sans": "Fira Sans",
  "betterplace-font-weights-regular": 400,
  "betterplace-font-weights-bold": 700,
  "betterplace-text-case-none": "none",
  "betterplace-text-case-capitalize": "capitalize",
  "betterplace-text-decoration-none": "none",
  "betterplace-text-decoration-underline": "underline",
  "betterplace-line-heights-xs": 1.1,
  "betterplace-line-heights-sm": 1.2,
  "betterplace-line-heights-base": 1.5,
  "betterplace-variables-min-font-size": "1rem",
  "betterplace-variables-max-font-size": "1.125rem",
  "betterplace-variables-fluid-root-font-size": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-variables-font-size-scale-ratio": "1.1892",
  "betterplace-variables-width-of-zero-glyph": "0.5583",
  "betterplace-units-ch": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem) * 0.5583",
  "betterplace-units-rem": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-font-size-0": "1rem",
  "betterplace-font-size-1": "1.189rem",
  "betterplace-font-size-2": "1.414rem",
  "betterplace-font-size-3": "1.682rem",
  "betterplace-font-size-4": "2rem",
  "betterplace-font-size-5": "2.378rem",
  "betterplace-font-size-6": "2.828rem",
  "betterplace-font-size-7": "3.363rem",
  "betterplace-font-size-8": "4rem",
  "betterplace-font-size-9": "4.757rem",
  "betterplace-font-size-10": "5.657rem",
  "betterplace-font-size--3": "0.595rem",
  "betterplace-font-size--2": "0.707rem",
  "betterplace-font-size--1": "0.878rem",
  "betterplace-measure-2xs": "25ch",
  "betterplace-measure-xs": "36ch",
  "betterplace-measure-sm": "49ch",
  "betterplace-measure-md": "64ch",
  "betterplace-measure-lg": "81ch",
  "betterplace-measure-xl": "100ch",
  "betterplace-measure-2xl": "121ch",
  "betterplace-viewport-width": "90rem",
  "betterplace-viewport-min": "22.5rem",
  "betterplace-viewport-max": "90rem",
  "betterplace-typography-xs": {
    fontWeight: 400,
    fontSize: "0.707rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-narrow": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold-narrow": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base": {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base-bold": {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg": {
    fontWeight: 400,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg-bold": {
    fontWeight: 700,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl": {
    fontWeight: 400,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl-bold": {
    fontWeight: 700,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-2xl-bold": {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-3xl-bold": {
    fontWeight: 700,
    fontSize: "3.363rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-4xl-bold": {
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-sizing-fix-1": "20rem",
  "betterplace-sizing-fix-2": "23.4375rem",
  "betterplace-sizing-fix-3": "48.75rem",
  "betterplace-sizing-fix-4": "60rem",
  "betterplace-sizing-fix-5": "52.875rem",
  "betterplace-sizing-fix-6": "90rem",
  "betterplace-shadow-outer-neutral-soft-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-green-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-red-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-1": {
    x: "0rem",
    y: "-0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-2": {
    x: "0rem",
    y: "-0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-3": {
    x: "0rem",
    y: "-0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-4": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-5": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-6": {
    x: "0rem",
    y: "-0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-red-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-green-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#2a5807"
  }
}, Ft = (e) => e.substring(1, 3) === "00" && e.length === 9 ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEX////Z5exdL3Q7AAAAGklEQVQY02NgAAFGRkaa0gzo4gzo6hgGiTsANBAAYf1ZdsIAAAAASUVORK5CYII=')" : e;
function W(e) {
  const t = Nt[e];
  return typeof t != "object" && typeof t != "number" ? t : "#";
}
const Lt = ["mousedown", "touchstart"], Ot = (e, t, r = Lt) => {
  const n = w(
    (a) => {
      e.current && a.target instanceof Element && !e.current.contains(a.target) && t();
    },
    [t, e]
  );
  E(() => {
    for (const a of r)
      document.addEventListener(a, n);
    return () => {
      for (const a of r)
        document.removeEventListener(a, n);
    };
  });
}, jt = (e) => {
  const t = w(
    (r) => {
      r.key === "Escape" && e();
    },
    [e]
  );
  E(() => (document.addEventListener("keydown", t), () => {
    document.removeEventListener("keydown", t);
  }), [t]);
}, zt = () => {
  const [e, t] = K(!1), r = w(
    (l) => {
      l && l.preventDefault(), t(!0);
    },
    [t]
  ), n = w(
    (l) => {
      l && l.preventDefault(), t(!1);
    },
    [t]
  ), a = w(
    (l) => {
      l && l.preventDefault(), t(!e);
    },
    [t, e]
  );
  jt(n);
  const o = $(
    () => e ? /* @__PURE__ */ f.jsx(
      "div",
      {
        "aria-hidden": "true",
        className: "modal",
        style: { display: "block", background: "black", opacity: 0.1 },
        onClick: () => t(!1)
      }
    ) : null,
    [t, e]
  );
  return { active: e, background: o, hide: n, show: r, toggle: a };
}, De = X(function({
  onClick: t,
  color: r,
  triggerSwatch: n = !1,
  className: a,
  "aria-describedby": o,
  ...l
}, c) {
  return /* @__PURE__ */ f.jsxs(
    "button",
    {
      ...l,
      className: M(q.swatch, a, {
        [q.activeSwatch]: n
      }),
      onClick: t,
      ref: c,
      "aria-roledescription": n ? "colour well" : "button",
      "aria-describedby": o,
      children: [
        /* @__PURE__ */ f.jsx(
          "div",
          {
            style: {
              background: Ft(r)
            },
            className: q.swatchInner
          }
        ),
        /* @__PURE__ */ f.jsx("span", { className: "sr-only", children: r })
      ]
    }
  );
});
function It({
  color: e,
  presetColors: t,
  onChange: r,
  hexInputLabel: n,
  label: a,
  id: o,
  classNames: l,
  ...c
}) {
  const i = Ce(), s = o || i, d = I(null), k = I(null), m = zt(), g = w(
    (b) => {
      b.key === "Enter" && (b.preventDefault(), m.hide());
    },
    [m]
  ), v = w(() => {
    var b;
    (b = k.current) == null || b.focus(), m.hide();
  }, [m]);
  return Ot(d, v), /* @__PURE__ */ f.jsxs("div", { className: q.picker, children: [
    /* @__PURE__ */ f.jsx("div", { className: M(q.label, l == null ? void 0 : l.label), id: `label-${s}`, children: a }),
    /* @__PURE__ */ f.jsx(
      De,
      {
        ...c,
        "aria-describedby": `label-${s}`,
        id: s,
        color: e,
        onClick: (b) => {
          b.preventDefault(), m.toggle();
        },
        ref: k,
        triggerSwatch: !0,
        className: l == null ? void 0 : l.trigger
      }
    ),
    m.active && /* @__PURE__ */ f.jsx("div", { className: q.popover, ref: d, children: /* @__PURE__ */ f.jsxs("div", { className: q.pickerSwatches, children: [
      t.map((b) => /* @__PURE__ */ ct(
        De,
        {
          ...c,
          key: b,
          color: b,
          onClick: (y) => {
            y.preventDefault(), v(), r(b);
          },
          className: M({
            [q.activeSwatch]: b === e,
            [q.inactiveSwatch]: b !== e
          })
        }
      )),
      /* @__PURE__ */ f.jsx("label", { htmlFor: "hexColorInput", className: "sr-only", children: n }),
      /* @__PURE__ */ f.jsx(
        Pt,
        {
          id: "hexColorInput",
          color: e,
          onChange: r,
          alpha: !0,
          prefixed: !0,
          className: q.input,
          onKeyDown: g
        }
      )
    ] }) })
  ] });
}
function Sr({
  onColorChange: e,
  transparentOption: t = !1,
  value: r,
  hexInputLabel: n,
  label: a,
  ...o
}) {
  const l = [
    W("betterplace-color-light"),
    W("betterplace-color-green-500"),
    W("betterplace-color-green-700"),
    W("betterplace-color-green-900"),
    W("betterplace-color-yellow-400"),
    W("betterplace-me-color-orange-500"),
    W("betterplace-color-red-700"),
    W("betterplace-color-red-900"),
    // themeColor('orange-700'),
    // themeColor('blue-400'),
    // themeColor('blue-700'),
    // themeColor('blue-900'),
    W("betterplace-color-purple-400"),
    W("betterplace-color-purple-700"),
    W("betterplace-color-gray-500"),
    W("betterplace-color-gray-700"),
    W("betterplace-color-gray-900")
  ].filter(Boolean);
  return t && l.push("#00000000"), /* @__PURE__ */ f.jsx(
    It,
    {
      ...o,
      color: `#${r === "transparent" ? "00000000" : r}`,
      presetColors: l,
      onChange: e,
      hexInputLabel: n,
      label: a
    }
  );
}
const Dt = "_dropdownContent_1n9sv_1", Mt = "_dropdownTriggerButton_1n9sv_14", Bt = "_scaleTransition_1n9sv_26", $t = "_scaleTransitionOpen_1n9sv_34", Ht = "_dropdownMenu_1n9sv_39", Wt = "_dropdownMenuSeparator_1n9sv_50", Kt = "_dropdownMenuItem_1n9sv_59", V = {
  dropdownContent: Dt,
  dropdownTriggerButton: Mt,
  scaleTransition: Bt,
  scaleTransitionOpen: $t,
  dropdownMenu: Ht,
  dropdownMenuSeparator: Wt,
  dropdownMenuItem: Kt
}, Ut = "_popupContainer_y7ppu_1", Yt = "_spyElement_y7ppu_15", qt = "_nonFocusableOutline_y7ppu_21", _e = {
  popupContainer: Ut,
  spyElement: Yt,
  nonFocusableOutline: qt
}, D = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right"
}, G = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  JUSTIFY: "justify"
}, Rt = {
  a: "link",
  area: "link",
  article: "article",
  aside: "complementary",
  button: "button",
  details: "group",
  dfn: "term",
  dialog: "dialog",
  fieldset: "group",
  figure: "figure",
  footer: "contentinfo",
  form: "form",
  h1: "heading",
  h2: "heading",
  h3: "heading",
  h4: "heading",
  h5: "heading",
  h6: "heading",
  header: "banner",
  hr: "separator",
  iframe: "document",
  img: "img",
  input: null,
  // for type purposes this value needs to be included
  li: "listitem",
  main: "main",
  menu: "list",
  menuitem: "menuitem",
  meter: "meter",
  nav: "navigation",
  ol: "list",
  option: "option",
  progress: "progressbar",
  section: "region",
  select: "listbox",
  summary: "button",
  table: "table",
  tbody: "rowgroup",
  td: "cell",
  textarea: "textbox",
  tfoot: "rowgroup",
  th: "columnheader",
  thead: "rowgroup",
  tr: "row",
  ul: "list"
}, Gt = {
  button: "button",
  checkbox: "checkbox",
  radio: "radio",
  range: "slider",
  text: "textbox",
  password: "textbox",
  email: "textbox",
  search: "searchbox",
  number: "spinbutton",
  tel: "textbox",
  url: "textbox",
  submit: "button",
  reset: "button",
  image: "button",
  file: "textbox"
}, J = ["a", "button", "input", "textarea", "select", "details", "area"];
function Xt(e) {
  return !e || !e.trim() ? /* @__PURE__ */ new Set() : new Set(e.trim().split(/\s+/));
}
function Jt(e, t) {
  return [...Xt(e).add(t)].join(" ");
}
function Qt(e) {
  const t = e.getAttribute("role");
  if (t) return t;
  const r = e.tagName.toLowerCase();
  if (r === "input") {
    const a = e.getAttribute("type");
    return a ? Gt[a] : "textbox";
  }
  const n = Rt[r];
  return n || "button";
}
function Ke(e, t = J) {
  if (!(!e || !(e instanceof HTMLElement)))
    return e.querySelectorAll(`${t.join(",")},[tabindex]:not([tabindex="-1"])`);
}
function Ue(e, t = J) {
  if (!e) return !1;
  const r = e.getAttribute("tabindex");
  if (!r && !t.includes(e.tagName.toLowerCase()) || r && parseInt(r) < 0 || e.hasAttribute("disabled")) return !1;
  const a = window.getComputedStyle(e);
  return !(a.display === "none" || a.visibility === "hidden");
}
function me(e, t = J) {
  if (!e || !(e instanceof HTMLElement)) return null;
  const r = e.querySelectorAll(
    `${t.join(",")}:not([tabindex="-1"]):not([disabled]):not([inert]),[tabindex]:not([tabindex="-1"]):not([disabled]):not([inert])`
  );
  for (const n of r)
    if (Ue(n, t))
      return n;
  return null;
}
function Ee(e, t = J) {
  return !e || !(e instanceof HTMLElement) ? null : Ue(e, t) ? e : me(e, t);
}
function Ye(e, t, r = J) {
  if (!t || !(t instanceof HTMLElement)) return null;
  let n = t, a = null;
  for (; (n = e(n)) && !(a = Ee(n, r)); )
    ;
  return a;
}
function qe(e, t = J) {
  return Ye((r) => (r == null ? void 0 : r.nextElementSibling) ?? null, e, t);
}
function Vt(e, t = J) {
  return Ye((r) => (r == null ? void 0 : r.previousElementSibling) ?? null, e, t);
}
function Re(e, t = J) {
  if (!e || !(e instanceof HTMLElement)) return null;
  let r = e;
  for (; ; ) {
    const n = qe(r, t);
    if (n) return n;
    if (r = (r == null ? void 0 : r.parentElement) ?? null, !r || r === e) return null;
  }
}
async function Zt() {
  return new Promise((e) => {
    setTimeout(() => window.requestAnimationFrame(() => e()));
  });
}
const Ae = "data-popup", ke = "data-popup-id";
function Me(e) {
  let t = e;
  for (; t; ) {
    if (t != null && t.hasAttribute(Ae)) return t;
    t = (t == null ? void 0 : t.parentElement) ?? null;
  }
  return null;
}
function er(e, t) {
  if (!t || !e) return !1;
  const r = Me(t);
  if (console.log("isElementTriggerWithinTarget", e, r), !r) return !1;
  const n = r.getAttribute(Ae);
  if (!n) return !1;
  if (e.getAttribute(ke) === n) return !0;
  const a = Me(e);
  return a ? !!a.querySelector(`[${ke}="${n}"]`) : !1;
}
function de(e) {
  return e ? e.clientHeight && e.scrollHeight > e.clientHeight || e.tagName === "DIALOG" ? e.tagName === "HTML" ? window : e : de(e.parentNode) : null;
}
const Ge = lt({
  initialised: !1,
  open: !1,
  inDOM: !1,
  setInDOM: () => {
  },
  showPopup: () => {
  },
  hidePopup: () => {
  },
  togglePopup: () => {
  },
  hasClickTrigger: !1,
  hasKeypressTrigger: !1,
  hasFocusTrigger: !1,
  hasHoverTrigger: !1
});
function re() {
  return it(Ge);
}
function tr(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function Xe(e) {
  return (t) => t === e || t && tr(t, e) ? t : e;
}
const A = {
  Enter: "Enter",
  Space: " ",
  Tab: "Tab",
  Escape: "Escape",
  Down: "ArrowDown",
  Up: "ArrowUp",
  Left: "ArrowLeft",
  Right: "ArrowRight"
};
function fe() {
  const e = document.getElementById("bp-portal-target");
  if (e) return e;
  const t = document.createElement("div");
  return t.id = "bp-portal-target", t.style.position = "absolute", t.setAttribute("role", "popup"), document.body.appendChild(t), t;
}
function rr(e) {
  if (!e) return null;
  const { height: t, width: r } = e;
  return {
    height: t,
    width: r
  };
}
function nr({ triggerRect: e, id: t, onCloseTransitionEnd: r, onPopupLeave: n, children: a }) {
  const {
    popupRef: o,
    spyRef: l,
    preferredPopupPosition: c,
    alignPopup: i,
    hasHoverTrigger: s,
    Anchor: d,
    contentClassName: k,
    contentWrapperClassName: m,
    popupOpenWrapperClassName: g,
    anchorClassName: v
  } = re(), [b, y] = K(null), [p, x] = K(null), [S, N] = K(null), [L, O] = K(!1), { open: j, hidePopup: u, popupRef: h } = re();
  E(() => {
    if (!j) return O(!1);
    const _ = window.requestAnimationFrame(() => {
      O(!0);
    });
    return () => window.cancelAnimationFrame(_);
  }, [j, O]);
  const z = w(() => {
    var P;
    const _ = rr((P = o == null ? void 0 : o.current) == null ? void 0 : P.getBoundingClientRect());
    y(Xe(_));
  }, [o]), T = w(() => {
    const _ = de(fe()) ?? document.body, Y = (_ === window ? document.body : _).getBoundingClientRect();
    x(Y.width), N(Y.height);
  }, []), B = w(
    (_) => {
      var Y;
      if (_.key !== A.Escape) return;
      _.preventDefault();
      const P = (Y = l == null ? void 0 : l.current) == null ? void 0 : Y.nextElementSibling;
      P == null || P.focus(), u();
    },
    [u, l]
  );
  E(() => {
    const _ = o == null ? void 0 : o.current;
    if (!_) return;
    const P = Ke(_);
    return P == null || P.forEach((Y, le) => {
      Y.addEventListener("keydown", B);
    }), () => {
      P == null || P.forEach((Y, le) => {
        Y.removeEventListener("keydown", B);
      });
    };
  }, [a, B, o]), Be(() => {
    z(), T();
  }, [z, T]);
  const U = w(() => {
    window.requestAnimationFrame(() => {
      z(), T();
    });
  }, [z, T]), C = w(
    (_) => {
      s && n(_);
    },
    [n, s]
  ), F = w(
    (_) => {
      var Y, le, Pe;
      const P = (le = (Y = h == null ? void 0 : h.current) == null ? void 0 : Y.firstElementChild) == null ? void 0 : le.firstElementChild;
      if (_.target !== P && !((Pe = o == null ? void 0 : o.current) != null && Pe.contains(_.relatedTarget))) {
        if (er(P, _.relatedTarget))
          return _.preventDefault();
        n(_);
      }
    },
    [n, h, o]
  );
  E(() => (window.addEventListener("resize", U), () => {
    window.removeEventListener("resize", U);
  }), [U]);
  const Z = $(() => d ? 17 : 0, [d]);
  E(() => {
    if (j) return;
    const _ = o == null ? void 0 : o.current;
    if (!g) return r();
    _ == null || _.addEventListener("transitionend", r);
    const P = setTimeout(r, 300);
    return () => {
      clearTimeout(P), _ == null || _.removeEventListener("transitionend", r);
    };
  }, [o, j, r, g]);
  const {
    x: ne,
    y: oe,
    anchorOffsetX: ae,
    position: ce,
    width: Qe
  } = $(
    () => ir({
      triggerRect: e,
      popupSize: b,
      bodyWidth: p,
      bodyHeight: S,
      anchorSize: Z,
      preferredPopupPosition: c,
      alignPopup: i
    }),
    [e, b, p, S, Z, c, i]
  );
  return ut(
    /* @__PURE__ */ f.jsx(
      "div",
      {
        id: t,
        className: _e.popupContainer,
        ref: o,
        onPointerLeave: C,
        onBlur: F,
        style: {
          transform: `translate3d(${ne}px, ${oe}px, 0)`
        },
        children: /* @__PURE__ */ f.jsxs(
          "div",
          {
            className: M(
              m,
              g && { [g]: L }
            ),
            children: [
              d && ce === D.BOTTOM && /* @__PURE__ */ f.jsx(d, { position: ce, x: ae, className: v }),
              /* @__PURE__ */ f.jsx(
                "div",
                {
                  tabIndex: -1,
                  className: k,
                  style: {
                    ...i === G.JUSTIFY ? { minWidth: Qe, overflowX: "hidden" } : {}
                  },
                  [Ae]: t,
                  children: a
                }
              ),
              d && ce === D.TOP && /* @__PURE__ */ f.jsx(d, { position: ce, x: ae, className: v })
            ]
          }
        )
      }
    ),
    fe()
  );
}
function or(e, t, r) {
  E(() => {
    var d;
    const n = (d = r == null ? void 0 : r.current) == null ? void 0 : d.nextElementSibling;
    if (!n) return;
    const a = me(n), o = a ?? n;
    n.setAttribute(ke, e);
    const c = o.getAttribute("aria-roledescription") ?? Qt(o) ?? "button";
    o.setAttribute("role", "button"), a || (o == null || o.setAttribute("tabindex", "0"), o == null || o.classList.add(_e.nonFocusableOutline)), o.setAttribute("aria-roledescription", c);
    const i = o.getAttribute("aria-describedby");
    (i == null ? void 0 : i.includes(e)) || o.setAttribute("aria-describedby", Jt(i, e));
  }, [t, e, r]);
}
function ar(e, t, r) {
  if (!e) return null;
  const { left: n, top: a, bottom: o, width: l } = e;
  return {
    left: n,
    top: a,
    bottom: o,
    width: l,
    offsetX: r,
    offsetY: t
  };
}
function cr({ children: e, content: t, id: r }) {
  const {
    showPopup: n,
    hidePopup: a,
    togglePopup: o,
    open: l,
    spyRef: c,
    popupRef: i,
    hasClickTrigger: s,
    hasFocusTrigger: d,
    hasHoverTrigger: k,
    hasKeypressTrigger: m,
    setInDOM: g
  } = re(), [v, b] = K(null), y = $(() => !!v, [v]);
  or(r, e, c);
  const p = w(() => {
    var C;
    const u = (C = c == null ? void 0 : c.current) == null ? void 0 : C.nextSibling;
    let h = u == null ? void 0 : u.getBoundingClientRect();
    h != null && h.height || (h = void 0);
    let z = 0, T = 0;
    const B = de(fe()) ?? window;
    if (B !== window) {
      const F = B;
      z = -F.getBoundingClientRect().top + F.scrollTop, T = -F.getBoundingClientRect().left + F.scrollLeft;
    }
    const U = ar(h, z, T);
    b(Xe(U)), g(!0);
  }, [g, c]), x = w(() => {
    b(null), g(!1);
  }, [g]), S = w(() => {
    y && p();
  }, [p, y]), N = w(
    (u) => {
      const h = i == null ? void 0 : i.current;
      if (u.relatedTarget instanceof Node && (h != null && h.contains(u.relatedTarget)))
        return u.preventDefault();
      a();
    },
    [a, i]
  ), L = w(
    (u) => {
      var h, z;
      if (u.relatedTarget instanceof Node && ((z = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextSibling) != null && z.contains(u.relatedTarget)))
        return u.preventDefault();
      a();
    },
    [a, c]
  ), O = w(
    (u) => {
      var z, T, B;
      const h = u.target instanceof Node;
      h && ((z = i == null ? void 0 : i.current) != null && z.contains(u.target)) || h && ((B = (T = c == null ? void 0 : c.current) == null ? void 0 : T.nextElementSibling) != null && B.contains(u.target)) || L(u);
    },
    [L, i, c]
  ), j = w(
    (u) => {
      if (u.key === A.Enter || u.key === A.Space)
        return u.preventDefault(), o();
      if (u.key === A.Escape) return a();
    },
    [a, o]
  );
  return E(() => {
    if (s)
      return document.addEventListener("pointerdown", O), () => {
        document.removeEventListener("pointerdown", O);
      };
  }, [s, O]), E(() => {
    var h;
    if (!s) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("pointerdown", o), () => {
      u == null || u.removeEventListener("pointerdown", o);
    };
  }, [s, o, c, e]), E(() => {
    var h;
    if (!k) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("mouseover", n), u == null || u.addEventListener("mouseleave", N), () => {
      u == null || u.removeEventListener("mouseover", n), u == null || u.removeEventListener("mouseleave", N);
    };
  }, [k, N, n, c, e]), E(() => {
    var h;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("focusout", N), () => u == null ? void 0 : u.removeEventListener("focusout", N);
  }, [N, c, e]), E(() => {
    var h;
    if (!d) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("focus", n), u == null || u.addEventListener("focusin", n), () => {
      u == null || u.removeEventListener("focus", n), u == null || u.removeEventListener("focusin", n);
    };
  }, [d, n, e, c]), E(() => {
    var h;
    if (!m) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("keydown", j), () => {
      u == null || u.removeEventListener("keydown", j);
    };
  }, [m, j, c, e]), E(() => {
    if (!l) return;
    window.addEventListener("resize", S, { passive: !0 });
    const u = de(fe()) ?? window;
    return u.addEventListener("scroll", S, { passive: !0 }), () => {
      window.removeEventListener("resize", S), u.removeEventListener("scroll", S);
    };
  }, [l, S]), Be(() => {
    S();
  }, [e, S]), E(() => {
    l && p();
  }, [l, p]), /* @__PURE__ */ f.jsxs(f.Fragment, { children: [
    /* @__PURE__ */ f.jsx("div", { ref: c, className: _e.spyElement }),
    e,
    y && /* @__PURE__ */ f.jsx(
      nr,
      {
        triggerRect: v,
        onCloseTransitionEnd: x,
        id: r,
        onPopupLeave: L,
        children: t
      }
    )
  ] });
}
function lr({
  children: e,
  content: t,
  id: r,
  triggerEvents: n = ["hover", "focus"],
  inactive: a,
  ...o
}) {
  const [l, c] = K(!1), [i, s] = K(!1), [d, k] = K(!1), m = I(null), g = I(null), v = re(), b = Ce(), y = $(() => r ?? b, [r]), p = $(() => n == null ? void 0 : n.includes("click"), [n]), x = $(() => n == null ? void 0 : n.includes("focus"), [n]), S = $(() => n == null ? void 0 : n.includes("hover"), [n]), N = $(() => n == null ? void 0 : n.includes("keypress"), [n]), L = w(() => {
    s(!1);
  }, []), O = w(() => {
    s(!0);
  }, []), j = w(() => {
    s((u) => !u);
  }, []);
  return E(() => (c(!0), () => {
    c(!1);
  }), []), !l || a ? e : /* @__PURE__ */ f.jsx(
    Ge.Provider,
    {
      value: {
        open: i,
        showPopup: O,
        hidePopup: L,
        togglePopup: j,
        spyRef: g,
        popupRef: m,
        hasClickTrigger: p,
        hasHoverTrigger: S,
        hasFocusTrigger: x,
        hasKeypressTrigger: N,
        inDOM: d,
        setInDOM: k,
        initialised: !0,
        parentContext: v,
        ...o
      },
      children: /* @__PURE__ */ f.jsx(cr, { content: t, id: y, children: e })
    }
  );
}
function ir({
  triggerRect: e,
  popupSize: t,
  bodyWidth: r,
  bodyHeight: n,
  originalMargin: a = 20,
  anchorSize: o = 17,
  preferredPopupPosition: l = D.TOP,
  alignPopup: c = G.CENTER
}) {
  let i = 0, s = 0, d = 0, k = 0, m = l, g = (t == null ? void 0 : t.width) ?? 0;
  if (!e || !t || !r || !n)
    return { x: i, y: s, anchorOffsetX: k, position: m, width: g };
  let v = 0, b = t.width / 2;
  const y = e.width / 2;
  c === G.CENTER ? i = e.left + e.offsetX - b + y : c === G.LEFT || c === G.JUSTIFY ? (i = e.left + e.offsetX, c === G.JUSTIFY && (g = e.width, b = y)) : c === G.RIGHT && (i = e.left + e.offsetX + e.width - g);
  const p = t.width > r ? 0 : Math.min(a, (r - g) / 2);
  if (v = i, d = i + t.width, i < p ? i = p : d > r - p && (i -= d - (r - p)), o) {
    k = v - i + b - o;
    const x = k + 2 * o - g;
    if (x > 0) {
      const S = Math.min(x, p);
      i += S, k -= S;
    }
  }
  return l === D.TOP ? (s = e.top + e.offsetY - t.height, s < Math.abs(e.offsetY) && (m = D.BOTTOM, s = e.bottom)) : (s = e.bottom + e.offsetY, s + t.height > n + e.offsetY && (m = D.TOP, s = e.top + e.offsetY - t.height)), { x: i, y: s, anchorOffsetX: k, position: m, width: g };
}
const sr = ["click", "keypress"];
function _r({
  children: e,
  style: t,
  className: r,
  id: n
}) {
  return /* @__PURE__ */ f.jsxs(
    "button",
    {
      id: n,
      style: t,
      className: M(se.selectContainer, se.select, V.dropdownTriggerButton, r),
      type: "button",
      children: [
        e,
        /* @__PURE__ */ f.jsx(tt, { name: "arrowDown" })
      ]
    }
  );
}
function Er({
  children: e,
  className: t,
  required: r,
  hint: n,
  id: a
}) {
  return /* @__PURE__ */ f.jsx("div", { children: e ? /* @__PURE__ */ f.jsxs("label", { htmlFor: a, className: M(se.label, t), children: [
    /* @__PURE__ */ f.jsxs("div", { children: [
      e,
      r && /* @__PURE__ */ f.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    n ? /* @__PURE__ */ f.jsx("div", { className: M(se.hint), children: n }) : null
  ] }) : null });
}
function Ar({ children: e, className: t }) {
  return /* @__PURE__ */ f.jsx("ul", { className: M(V.dropdownMenu, t), children: e });
}
function Pr({
  children: e,
  style: t,
  className: r
}) {
  const n = I(null), { preferredPopupPosition: a, hidePopup: o, parentContext: l, spyRef: c } = he(), i = w(
    (s) => {
      var m, g;
      const d = l && a, k = d && Je[d].close;
      if (s.key === A.Down || s.key === A.Up) {
        s.preventDefault();
        const v = s.key === A.Up ? Vt(n.current) : qe(n.current);
        v == null || v.focus();
        return;
      }
      s.key === k && (s.preventDefault(), (g = Ee((m = c == null ? void 0 : c.current) == null ? void 0 : m.nextElementSibling)) == null || g.focus(), o()), s.key === A.Enter && s.target instanceof HTMLElement && "click" in s.target && s.target.click();
    },
    [o, l, a, c]
  );
  return E(() => {
    const s = me(n.current);
    if (s)
      return s.addEventListener("keydown", i), s.addEventListener("click", o), () => {
        s.removeEventListener("keydown", i), s.removeEventListener("click", o);
      };
  }, [o, i]), /* @__PURE__ */ f.jsx("li", { ref: n, style: t, className: M(V.dropdownMenuItem, r), children: e });
}
function Nr() {
  return /* @__PURE__ */ f.jsx("li", { className: V.dropdownMenuSeparator });
}
const Je = {
  [D.BOTTOM]: { open: A.Down, close: A.Down },
  [D.TOP]: { open: A.Up, close: A.Down },
  [D.RIGHT]: { open: A.Right, close: A.Left },
  [D.LEFT]: { open: A.Left, close: A.Right }
};
function ur({ children: e }) {
  const { spyRef: t, popupRef: r, showPopup: n, preferredPopupPosition: a } = he(), o = w(
    (l) => {
      const c = a ?? D.BOTTOM, i = Je[c].open;
      (l.key === i || l.key === A.Tab && !l.shiftKey) && (l.preventDefault(), n(), Zt().then(() => {
        var d;
        let s = me(r == null ? void 0 : r.current);
        if (s) {
          s.focus();
          return;
        }
        l.key === A.Tab && (s = Re((d = t == null ? void 0 : t.current) == null ? void 0 : d.nextElementSibling), s == null || s.focus());
      }));
    },
    [r, a, n, t]
  );
  return E(() => {
    var c;
    const l = (c = t == null ? void 0 : t.current) == null ? void 0 : c.nextElementSibling;
    if (l)
      return l.addEventListener("keydown", o), () => l.removeEventListener("keydown", o);
  }, [o, t]), e;
}
function pr({ children: e }) {
  const { spyRef: t, popupRef: r, hidePopup: n, open: a } = he(), o = w(
    (c) => {
      var i, s;
      c.key !== A.Tab || !c.shiftKey || (c.preventDefault(), (s = Ee((i = t == null ? void 0 : t.current) == null ? void 0 : i.nextElementSibling)) == null || s.focus(), n());
    },
    [t, n]
  ), l = w(
    (c) => {
      var s;
      if (c.key !== A.Tab || c.shiftKey) return;
      c.preventDefault();
      const i = Re((s = t == null ? void 0 : t.current) == null ? void 0 : s.nextElementSibling);
      i == null || i.focus();
    },
    [t]
  );
  return E(() => {
    const c = r == null ? void 0 : r.current;
    if (!c) return;
    const i = Ke(c);
    if (!i || !i.length) return;
    const s = i.item(0), d = i.item(i.length - 1);
    return s.addEventListener("keydown", o), d.addEventListener("keydown", l), () => {
      s.removeEventListener("keydown", o), d.removeEventListener("keydown", l);
    };
  }, [o, l, t, r, e, a]), e;
}
function Fr({
  trigger: e,
  children: t,
  className: r,
  alignContent: n = G.JUSTIFY,
  wrapperClassName: a,
  anchorClassName: o,
  preferredPopupPosition: l,
  Anchor: c
}) {
  const { parentContext: i } = he(), s = l ?? (i ? i.preferredPopupPosition ?? D.RIGHT : D.BOTTOM);
  return /* @__PURE__ */ f.jsx(
    lr,
    {
      content: /* @__PURE__ */ f.jsx(pr, { children: t }),
      contentClassName: M(V.dropdownContent, r),
      contentWrapperClassName: M(V.scaleTransition, a),
      popupOpenWrapperClassName: V.scaleTransitionOpen,
      triggerEvents: sr,
      preferredPopupPosition: s,
      alignPopup: n,
      anchorClassName: o,
      Anchor: c,
      children: /* @__PURE__ */ f.jsx(ur, { children: e })
    }
  );
}
function he() {
  const {
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: a,
    inDOM: o,
    parentContext: l,
    alignPopup: c,
    preferredPopupPosition: i
  } = re(), s = l && l.initialised ? l : void 0;
  return {
    open: o,
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: a,
    parentContext: s,
    alignPopup: c,
    preferredPopupPosition: i
  };
}
const Lr = X(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, l = o ? R : t, c = o ? R : r;
  return /* @__PURE__ */ f.jsx(rt, { ...n, onChange: l, onClick: c, ref: a });
}), Or = X(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, l = o ? R : t, c = o ? R : r;
  return /* @__PURE__ */ f.jsx(nt, { ...n, onChange: l, onClick: c, ref: a });
}), dr = "_input_1bj67_1", fr = "_growWrap_1bj67_2", ie = {
  input: dr,
  growWrap: fr,
  "input--error": "_input--error_1bj67_29"
}, jr = X(function({ label: t, name: r, description: n, error: a, warning: o, id: l, required: c, className: i, onChange: s, errorDisplay: d, rows: k = 1, ...m }, g) {
  const v = I(null), b = [];
  return a && b.push(`${r}-error`), o && b.push(`${r}-warning`), n && b.push(`${r}-description`), /* @__PURE__ */ f.jsx(
    ot,
    {
      label: t,
      name: r,
      description: n,
      error: a,
      warning: o,
      id: l || r,
      required: c,
      errorDisplay: d,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ f.jsx("div", { className: M(ie.growWrap, i, { [ie.wrapError]: !!a }), ref: v, children: /* @__PURE__ */ f.jsx(
        "textarea",
        {
          ...m,
          ref: g,
          id: l || r,
          name: r,
          "aria-describedby": b.length ? b.join(" ") : void 0,
          "aria-invalid": !!a,
          "aria-required": c,
          className: M(ie.input, i, { [ie["input--error"]]: !!a }),
          rows: k,
          onChange: (y) => {
            var p;
            (p = v.current) == null || p.setAttribute("data-replicated-value", y.target.value), s && s(y);
          }
        }
      ) })
    }
  );
}), br = "_button_akv9v_1", mr = "_hiddenInput_akv9v_62", ge = {
  button: br,
  hiddenInput: mr,
  "size-large": "_size-large_akv9v_68",
  "size-default": "_size-default_akv9v_73",
  "size-small": "_size-small_akv9v_78"
}, zr = X(function({
  id: t,
  children: r,
  value: n,
  name: a,
  onChange: o,
  onClick: l,
  className: c,
  type: i = "radio",
  size: s = "default",
  disabled_: d,
  disabled: k,
  ...m
}, g) {
  const v = M(ge.button, ge[`size-${s}`], c), b = Ce(), p = `${t || a || b}-${String(n)}`, x = k || d;
  return /* @__PURE__ */ f.jsxs("label", { htmlFor: p, className: v, children: [
    /* @__PURE__ */ f.jsx(
      "input",
      {
        ...m,
        ref: g,
        id: p,
        "aria-disabled": x,
        type: i,
        value: n,
        name: a,
        onClick: x ? R : l,
        onChange: x ? R : o,
        className: ge.hiddenInput,
        disabled: d
      }
    ),
    r
  ] });
}), Ir = X(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, l = o ? R : t, c = o ? R : r;
  return /* @__PURE__ */ f.jsx(at, { ...n, onChange: l, onClick: c, ref: a });
}), hr = "_tooltipArrow_qcdrl_1", gr = "_bottomTooltipArrow_qcdrl_32", wr = "_topTooltipArrow_qcdrl_37", we = {
  tooltipArrow: hr,
  bottomTooltipArrow: gr,
  topTooltipArrow: wr
};
function Dr({ x: e, position: t, className: r }) {
  return /* @__PURE__ */ f.jsx(
    "div",
    {
      className: M(we.tooltipArrow, r, {
        [we.bottomTooltipArrow]: t === D.BOTTOM,
        [we.topTooltipArrow]: t === D.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === D.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
function yr(e, t) {
  return {
    position: "absolute",
    inset: 0,
    transform: `translateY(${e * t}px)`,
    height: `${t}px`
  };
}
const ye = {
  Up: "Up",
  Down: "Down",
  None: null
};
function xr({
  className: e,
  style: t,
  children: r,
  overscanRows: n,
  maxRows: a,
  rowHeight: o,
  rows: l,
  onScroll: c,
  initialRow: i
}) {
  const s = I(null), d = n || l, k = I(/* @__PURE__ */ new Map()), m = I(null), g = I((i ?? 0) * o), [v, b] = K(0), y = I(ye.None), p = a ?? v + (l + d) * 2 + 1, x = l * o, S = o * p;
  function N(T) {
    return k.current.has(T) || k.current.set(T, yr(T, o)), k.current.get(T);
  }
  const L = w(
    (T) => {
      const B = Math.max(0, T - d), U = Math.min(
        p - 1,
        // don't render past the end of the list
        T + l + d
      );
      return [B, U];
    },
    [p, d, l]
  ), O = [], [j, u] = L(v);
  for (let T = j; T <= u; T++)
    O.push(/* @__PURE__ */ f.jsx(r, { index: T, style: N(T) }, T));
  const h = w(
    (T) => {
      m.current && clearTimeout(m.current), setTimeout(() => {
        c == null || c(T), m.current = null;
      }, 50);
    },
    [c]
  ), z = w(
    (T) => {
      const B = g.current;
      g.current = T.currentTarget.scrollTop;
      const U = Math.floor(g.current / o);
      y.current = B < g.current ? ye.Down : ye.Up, h(L(U)), b(U);
    },
    [L, h, o]
  );
  return E(() => {
    var T;
    i && ((T = s.current) == null || T.scrollTo(0, i * o));
  }, [i, o]), E(() => () => {
    m != null && m.current && clearTimeout(m.current);
  }, []), /* @__PURE__ */ f.jsx(
    "div",
    {
      className: e,
      style: {
        ...t,
        overflowY: "auto",
        position: "relative",
        scrollBehavior: "smooth",
        height: x,
        willChange: "contents"
      },
      ref: s,
      onScroll: z,
      children: /* @__PURE__ */ f.jsx("div", { style: { position: "absolute", top: 0, left: 0, right: 0, height: S }, children: O })
    }
  );
}
const Mr = st(xr);
export {
  G as A,
  Or as B,
  bt as C,
  Fr as D,
  zr as E,
  Ir as F,
  ft as G,
  A as K,
  lr as P,
  jr as T,
  Mr as V,
  Dr as a,
  D as b,
  Cr as c,
  Sr as d,
  Ar as e,
  Pr as f,
  Nr as g,
  _r as h,
  Er as i,
  Lr as j,
  Jt as k,
  Qt as l,
  Ke as m,
  me as n,
  Ee as o,
  qe as p,
  Vt as q,
  Re as r,
  Ae as s,
  Zt as t,
  ke as u,
  Me as v,
  er as w,
  de as x,
  Ge as y,
  re as z
};
